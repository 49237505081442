module.exports = [{
      plugin: require('/Users/ali/Documents/projects/www.gitconsensus.com/node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('/Users/ali/Documents/projects/www.gitconsensus.com/node_modules/gatsby-theme-material-ui/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/ali/Documents/projects/www.gitconsensus.com/node_modules/gatsby-plugin-fathom/gatsby-browser.js'),
      options: {"plugins":[],"trackingUrl":"fathom.tdvm.net","siteId":"GJMAV"},
    },{
      plugin: require('/Users/ali/Documents/projects/www.gitconsensus.com/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
